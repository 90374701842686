<template>
    <div>
        <div class="page-title">结算账户</div>

        <pay-box title="结算账户">
            <div v-if="ready">
                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">账户类型</div>

                    <div class="pay-apply-item-content">

                        <a-radio-group @change="typeChange" v-model="params.bank_account_info.bank_account_type">
                            <a-radio value="BANK_ACCOUNT_TYPE_CORPORATE">对公银行账户</a-radio>
                            <a-radio value="BANK_ACCOUNT_TYPE_PERSONAL">个人银行卡账户</a-radio>
                        </a-radio-group>

                    </div>

                </div>
                <div class="pay-apply-tips">你为经营者对公银行账户，请务必填写开户名为的银行账号</div>

                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">开户名称</div>

                    <div class="pay-apply-item-content">

                        {{ params.bank_account_info.account_name }}

                    </div>

                </div>
                <div class="pay-apply-tips">若因特殊原因需结算至非同名账户，在入驻完成后登录商户平台修改结算账户户名</div>


                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">开户行</div>

                    <div class="pay-apply-item-content">


                        <a-select show-search
                                  placeholder="选择银行"
                                  class="pay-apply-input"
                                  v-model="params.bank_account_info.account_bank"

                                  @change="bankChanged"
                        >
                            <template v-for=" (bank,key) in banks">

                                <a-select-option :key="key" :value="bank">{{ bank }}</a-select-option>

                            </template>

                        </a-select>

                    </div>

                </div>
                <div class="pay-apply-tips">城市商业银行、农村商业银行、信用合作联社及其他银行，请选择“其他银行”</div>


                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">开户银行省市</div>

                    <div class="pay-apply-item-content">

                        <a-select
                                show-search
                                :filter-option="false"
                                placeholder="输入关键词搜索省市区"
                                class="pay-apply-input"
                                @search="fetchArea"
                                v-model="params.bank_account_info.bank_address_code"
                        >
                            <template v-for="(item) in bankOptions">
                                <a-select-option
                                        :key="item.value"
                                        :value="item.value"> {{ item.label }}
                                </a-select-option>
                            </template>

                        </a-select>

                    </div>

                </div>
                <div class="pay-apply-tips">如果找不到所在城市，请选择所在地区或者上级城市</div>


                <template v-if="showBankName">
                    <div class="pay-apply-item">

                        <div class="pay-apply-item-title">开户银行全称</div>

                        <div class="pay-apply-item-content">

                            <at-input v-model="params.bank_account_info.bank_name" class="pay-apply-input"/>

                        </div>

                    </div>
                    <div class="pay-apply-tips">需填写银行全称，如"深圳农村商业银行XXX支行"</div>
                </template>

                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">银行账号</div>

                    <div class="pay-apply-item-content">

                        <at-input v-model="params.bank_account_info.account_number" class="pay-apply-input"/>

                    </div>

                </div>
            </div>
            <div v-else>
                <p>请先完成主体资料填写</p>
                <a @click="changeStep(3)">立即前往</a>
            </div>

        </pay-box>


        <div class="pay-apply-btns">
            <button @click="saveChange(2)" class="btn btn-white">返回</button>
            <button @click="saveChange(6)" class="btn">下一步</button>
        </div>
    </div>
</template>

<script>
    import AtInput from "../../../../components/AtInput";
    import PayBox from "./PayBox";
    import api from "../../../../repo/api";

    export default {
        name: "Step5",
        components: {PayBox, AtInput},
        props: {
            apply: Object
        },
        mounted() {
            this.init()
        },
        data() {
            return {
                bankOptions: [],
                ready: false,
                showBankName: true,
                params: {
                    bank_account_info: {
                        bank_account_type: '',
                        bank_address_code: '',
                        bank_name: '',
                        account_number: '',
                        account_name: '',
                        account_bank: '',
                    }
                },
                banks: [
                    '工商银行',
                    '交通银行',
                    '招商银行',
                    '民生银行',
                    '中信银行',
                    '浦发银行',
                    '兴业银行',
                    '光大银行',
                    '广发银行',
                    '平安银行',
                    '北京银行',
                    '华夏银行',
                    '农业银行',
                    '建设银行',
                    '邮政储蓄银行',
                    '中国银行',
                    '宁波银行',
                    '其他银行'
                ],
            }
        },
        methods: {
            init() {
                let info = this.apply['subject_info']['identity_info']

                if (!info) return

                info = info['id_card_info']

                if (!info) return

                this.params.bank_account_info = this.apply['bank_account_info'] || {}

                this.ready = true

                this.typeChangeVal(this.params.bank_account_info.bank_account_type)

                this.fetchArea(this.params.bank_account_info.bank_address_code)

                this.bankChanged(this.params.bank_account_info.account_bank)

            },
            typeChange(e) {
                this.typeChangeVal(e.target.value)
            },
            bankChanged(e) {

                if (e === '其他银行') {
                    this.showBankName = true

                    this.params.bank_account_info.bank_name =
                        this.params.bank_account_info.bank_name || ''

                    return
                }

                this.showBankName = false

                delete this.params.bank_account_info.bank_name
            },
            typeChangeVal(type) {

                if (!type) return;

                // 对公
                if (type === 'BANK_ACCOUNT_TYPE_CORPORATE') {
                    this.params.bank_account_info.account_name = this.apply['subject_info']['business_license_info']['merchant_name']
                }

                // 个人
                if (type === 'BANK_ACCOUNT_TYPE_PERSONAL') {
                    this.params.bank_account_info.account_name = this.apply['subject_info']['identity_info']['id_card_info']['id_card_name']
                }

                this.$forceUpdate()
            },
            fetchArea(keyword) {

                api.get('/pay-apply/bank-area', {keyword}, (data) => {
                    this.bankOptions = data.data
                    this.$forceUpdate()
                })

            },
            saveChange(step) {

                if (!this.ready) return this.changeStep(step)

                this.$loading.show()

                api.post('/pay-apply/save-bank-account-info', this.params, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) this.$message.data(data)

                    if (data.code === 0) {
                        this.$emit('change', step, data.data)
                    }

                })
            },
            changeStep(step) {
                this.$emit('change', step)
            }
        }
    }
</script>

<style scoped>

</style>