<template>
    <div>
        <div class="page-title">资料填写</div>

        <div>

            <pay-box class="pay-box-item" title="申请单信息">

                <div class="apply-info">
                    <div>
                        <span class="item-title">已选主体类型</span>
                        <span class="item-value">

                            <template v-if="apply.subject_info.subject_type === 'SUBJECT_TYPE_INDIVIDUAL' ">
                                个体工商
                            </template>
                            <template v-else>
                                企业
                            </template>


                        <a @click="showModal = true" class="edit-btn">修改</a>
                    </span>
                    </div>

                    <div>
                        <span class="item-title">创建时间</span>
                        <span class="item-value">{{ time }}</span>
                    </div>

                    <div>
                        <span class="item-title">申请单编号</span>
                        <span class="item-value">{{ code }}</span>
                    </div>
                </div>

            </pay-box>

            <pay-box class="pay-box-item" title="支付申请信息">

                <div class="content-list">

                    <div class="content-item"
                         :class="{active:isActive(['bank_account_info'])}"
                         @click="gotoStep(5)"
                    >

                        <div class="content-item-title">结算账户</div>
                        <div class="content-item-subtitle">请填写商家提现收款的银行账户信息</div>

                        <div class="right-tx active">
                            去填写<span class="icon"></span>
                        </div>
                        <div class="right-tx">
                            已填写<span class="icon"></span>
                        </div>

                    </div>

                    <div class="content-item"
                         :class="{active:isActive(['subject_info'])}"
                         @click="gotoStep(3)">

                        <div class="content-item-title">主体信息</div>
                        <div class="content-item-subtitle">请填写商家的营业执照/登记证书、经营者/法人的证件等信息</div>


                        <div class="right-tx active">
                            去填写<span class="icon"></span>
                        </div>
                        <div class="right-tx">
                            已填写<span class="icon"></span>
                        </div>

                    </div>

                    <div class="content-item"
                         :class="{active:isActive(['business_info'])}"
                         @click="gotoStep(4)"
                    >

                        <div class="content-item-title">经营信息</div>
                        <div class="content-item-subtitle">请填写商家的经营业务信息、售卖商品/提供服务场景信息</div>

                        <div class="right-tx active">
                            去填写<span class="icon"></span>
                        </div>
                        <div class="right-tx">
                            已填写<span class="icon"></span>
                        </div>
                    </div>

                    <div class="content-item"
                         :class="{active:isActive(['contact_info'])}"
                         @click="gotoStep(6)"
                    >

                        <div class="content-item-title">超级管理员</div>
                        <div class="content-item-subtitle">
                            请填写商家的超级管理员信息，超级管理员需在开户后进行签约，并接受日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人
                        </div>

                        <div class="right-tx active">
                            去填写<span class="icon"></span>
                        </div>
                        <div class="right-tx">
                            已填写<span class="icon"></span>
                        </div>

                    </div>

                </div>

            </pay-box>


            <button @click="submit" class="btn">提交</button>

        </div>

        <at-modal title="提示" v-model="showModal" @ok="gotoStep(1)">
            <div class="zt-ts">更改主体类型后，将清空所有已填写的资料，请确认 是否更改？</div>
        </at-modal>
    </div>
</template>

<script>
    import PayBox from "./PayBox";
    import AtModal from "../../../../components/AtModal";
    import api from "../../../../repo/api";

    export default {
        name: "Main",
        components: {AtModal, PayBox},
        props: {
            apply: Object,
            code: String,
            time: String,
        },
        methods: {
            gotoStep(step) {
                this.$emit('change', step)
            },
            isActive(keys) {

                for (let key of keys) {

                    if (key === 'subject_info') {
                        return (!this.isEmpty('business_license_info', this.apply['subject_info']))
                            && (!this.isEmpty('identity_info', this.apply['subject_info']))
                    }

                    if (this.isEmpty(key, this.apply)) return false
                }

                return true;
            },
            isEmpty(key, data) {

                if (!data) return true

                let val = data[key]

                if (val === false) return false;

                if (!val) return true;

                if (typeof val === 'object') {

                    for (let vk in val) {
                        if (this.isEmpty(vk, val)) return true
                    }

                }

                return false
            },
            submit() {
                this.$loading.show()

                api.post('/pay-apply/submit', {}, (data) => {

                    this.$loading.hide()

                    if (data.code === 0) {

                        this.gotoStep(7)

                    } else {
                        this.$message.data(data)
                    }


                })
            }
        },
        data() {
            return {
                showModal: false
            }
        },
    }
</script>

<style lang="less" scoped>

    .content-list {
        padding-left: 30px;
    }

    .apply-info {
        display: flex;
        justify-content: space-between;
    }

    .item-title {
        margin-right: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }

    .item-value {
        width: 70px;
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }

    .edit-btn {
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #FBB03B;
        line-height: 16px;
    }

    .content-item-title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
        margin-bottom: 8px;

        &:before {
            display: block;
            content: ' ';
            width: 12px;
            height: 12px;

            background-image: url("/assets/icons/jx-white.png");
            background-size: contain;
            position: absolute;
            left: -33px;
            top: 26px;
        }
    }

    .right-tx {
        position: absolute;
        right: 24px;
        top: 45px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;

        display: flex;
        align-items: center;

        .icon {
            display: inline-block;
            width: 12px;
            height: 12px;

            background: url("/assets/icons/pay-back.png");
            background-size: contain;
        }

        &.active {
            color: #FBB03B;

            .icon {
                background-image: url("/assets/icons/pay-back-active.png");
            }
        }
    }

    .content-item {
        padding: 23px 0;
        border-bottom: 1px solid #EEEEEE;
        position: relative;
        cursor: pointer;

        &:before {
            content: ' ';
            display: block;
            width: 1px;
            height: 64px;
            background-color: #EEEEEE;
            position: absolute;
            top: 46px;
            left: -28px;
        }

        .right-tx {
            display: none;

            &.active {
                display: flex;
            }
        }

        &.active {
            .content-item-title:before {
                background-image: url("/assets/icons/jx-black.png");
            }

            .right-tx {
                display: flex;

                &.active {
                    display: none;
                }
            }
        }

        &:last-child:before {
            display: none;
        }

        &:first-child {

            &:before {
                top: 31px;
            }

            padding-top: 8px;

            .content-item-title:before {
                top: 11px;
            }

            .right-tx {
                top: 30px;
            }
        }

        &:last-child {
            padding-bottom: 15px;
            border-bottom: 0;
        }
    }


    .content-item-subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
        padding-right: 160px;
    }
</style>