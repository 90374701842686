<template>
    <div>
        <div class="page-title">经营信息</div>

        <pay-box title="经营信息">

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">商户简称</div>

                <div class="pay-apply-item-content">

                    <at-input v-model="params.business_info.merchant_shortname" class="pay-apply-input"/>

                </div>

            </div>

            <div class="pay-apply-tips">
                <div>1、在支付完成页向买家展示，需与微信经营类目相关。 <br>
                    2、简称要求：<br>
                    ① 不支持单纯以人名来命名，若为个体户经营，可用“个体户＋经琶者名称”，或“经营者名称＋业务”命名，如”个体户张三”或“张三餐饮店” ; <br>
                    ② 不支持无实际意义的文案，如“ XX 特约商户”、“ 800”、“ XX 客服电话 XXX” ;
                </div>
            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">客服电话</div>

                <div class="pay-apply-item-content">

                    <at-input v-model="params.business_info.service_phone" class="pay-apply-input"/>

                </div>

            </div>
            <div class="pay-apply-tips">
                1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务 <br>
                2、请确保电话畅通，以便入驻后平台回拨确认。
            </div>


        </pay-box>

        <div class="pay-apply-btns">
            <button @click="changeStep(2)" class="btn btn-white">返回</button>
            <button @click="changeStep(5)" class="btn">下一步</button>
        </div>
    </div>
</template>

<script>
    import PayBox from "./PayBox";
    import AtInput from "../../../../components/AtInput";
    import api from "../../../../repo/api";

    export default {
        name: "Step4",
        components: {AtInput, PayBox},
        props: {
            apply: Object
        },
        data() {
            return {
                params: {
                    business_info: {
                        merchant_shortname: '',
                        service_phone: '',
                    }
                }
            }
        },
        mounted() {
            let info = this.apply['business_info']

            if (info) this.params.business_info = info
        },
        methods: {
            changeStep(step) {

                this.$loading.show()

                api.post('/pay-apply/save-business-info', this.params, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) this.$message.data(data)

                    if (data.code === 0) {
                        this.$emit('change', step, data.data)
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>