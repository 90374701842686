<template>
    <div>
        <div class="page-title">支付申请</div>

        <div class="zt-types">

            <div class="zt-type" @click="changeType('SUBJECT_TYPE_INDIVIDUAL')"
                 :class="{ 'active' :type === 'SUBJECT_TYPE_INDIVIDUAL' }">
                <div class="line-1"><img class="icon" src="/assets/icons/type-card-gr.png" alt=""></div>
                <div class="line-2">个体工商</div>
                <div class="line-3"></div>
                <div class="line-4">营业执照上的主体类型一般为个体户、个体工商户、个体经营</div>
            </div>

            <div class="zt-type" @click="changeType('SUBJECT_TYPE_ENTERPRISE')"
                 :class="{ 'active' :type === 'SUBJECT_TYPE_ENTERPRISE' }">
                <div class="line-1"><img class="icon" src="/assets/icons/type-card-qy.png" alt=""></div>
                <div class="line-2">企业</div>
                <div class="line-3"></div>
                <div class="line-4">营业执照上的主体类型一般为有限公司、有限责任公司</div>
            </div>

        </div>

    </div>
</template>

<script>
    import api from "../../../../repo/api";

    export default {
        name: "Index",
        components: {},
        props: {
            apply: Object
        },
        data() {
            return {
                type: '',
                showModal: false,
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                if (!this.apply['subject_info']) return

                this.type = this.apply['subject_info']['subject_type']
            },
            changeType(type) {

                if (this.type === type) return

                this.saveChange(type)
            },
            saveChange(type) {

                let tt = this.type
                this.type = type

                this.$loading.show()

                api.post('/pay-apply/init-apply', this.params(), (data) => {

                    this.$loading.hide()

                    if (data.code === 0) {

                        this.$emit('change', 2, data.data)

                    } else {
                        this.type = tt
                        this.$message.error('修改失败');

                    }

                })
            },
            params() {
                return {
                    subject_info: {
                        subject_type: this.type,
                        finance_institution: false
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .zt-types {
        display: flex;
        justify-content: left;
    }

    .zt-type {
        cursor: pointer;
        width: 240px;
        height: 224px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        padding: 24px;
        margin-right: 48px;

        &:active, &.active {
            border: 1px solid #333333;
        }

        .line-1 {
            margin-bottom: 24px;
        }

        .line-3 {
            height: 1px;
            border: 1px solid #EEEEEE;
            margin-bottom: 16px;
        }

        .line-2 {
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
        }

        .line-4 {
            width: 192px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }

        .icon {
            width: 24px;
        }
    }

    .zt-ts {
        margin: 0 auto;
        width: 322px;
        height: 44px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        text-align: center;
    }

</style>